@import (reference) "theme/variables.less";

.NotFoundContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: @breakpoint-mobile;
    padding: 120px 0;
    text-align: center;

    @media (min-width: @breakpoint-tablet) {
        max-width: @breakpoint-desktop-small;
        padding: 150px 0;
    }

    @media (min-width: @breakpoint-desktop-small) {
        max-width: @breakpoint-desktop-large;
        padding: 240px 0;
    }

    &-heading {
        font-size: @font-size-heading2;

        @media (min-width: @breakpoint-tablet) {
            font-size: @font-size-section;
        }
    }

    &-content {
        font-size: @font-size-chat;

        @media (min-width: @breakpoint-tablet) {
            font-size: @font-size-landing;
        }
    }

    &-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 250px;

        @media (min-width: @breakpoint-mobile) {
            flex-direction: row;
            width: 300px;
        }

        @media (min-width: @breakpoint-desktop-large) {
            width: 350px;
        }

        > a {
            margin-bottom: @spacing-small;
        }
    }
}

.Bold {
    font-weight: bold;
}

.MarginBottom {
    margin-bottom: @spacing-xxsmall;
}
